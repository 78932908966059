<template>
  <div>
    <v-row v-if="game_topic">
      <v-col>
        <v-row dense>
          <v-col cols="12">
            <h1>{{ game_topic.subject }}</h1>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-text v-html="compiledTopicContent"> </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="3">
        <v-card rounded="lg" align="center" justify="space-around">
          <v-card-text>
            <a href="http://play.torchship.org/player-client/">
              <v-btn color="primary" large width="80%"> Play Now </v-btn>
            </a>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import store from "@/store";
import { marked } from "marked";
import * as DOMPurify from "dompurify";

import { FETCH_GAME_TOPIC } from "@/store/actions.type";
import { BEGIN_LOADING, END_LOADING } from "@/store/mutations.type";

export default Vue.extend({
  name: "GameTopic",
  props: {
    topicKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["isLoading", "game_topic"]),
    compiledTopicContent() {
      if (
        this.game_topic === undefined ||
        this.game_topic.content === undefined
      )
        return "";
      return DOMPurify.sanitize(marked(this.game_topic.content.join("\r\n")));
    },
  },
  watch: {
    "$route.path": function () {
      this.init_component();
    },
  },
  created() {
    store.commit(BEGIN_LOADING);
    Promise.all([store.dispatch(FETCH_GAME_TOPIC, this.topicKey)]).then(() => {
      store.commit(END_LOADING);
    });
  },
  methods: {
    init_component: function () {
      store.commit(BEGIN_LOADING);
      Promise.all([store.dispatch(FETCH_GAME_TOPIC, this.topicKey)]).then(
        () => {
          store.commit(END_LOADING);
        }
      );
    },
  },
});
</script>
